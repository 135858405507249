* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

body {
    background-color: #0f1c2e;
    height: 100vh;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-box {
    background-color: #fff;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.logo img {
    width: 100px;
    margin-bottom: 20px;
}

h2 {
    margin-bottom: 20px;
    color: #333;
}

.textbox {
    margin-bottom: 20px;
}

.textbox input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.remember-me {
    margin-bottom: 20px;
    text-align: left;
}

.remember-me input {
    margin-right: 5px;
}

.forgot-password {
    margin-bottom: 20px;
    text-align: left;
}

.forgot-password a {
    color: #00b5ad;
    text-decoration: none;
}

.btn {
    border-radius: 20px;
    font-size: 14px;
    background: rgb(0, 191, 151);
    color: rgb(255, 255, 255);
    border: none;
    font-weight: 600;
    padding: 7px 24px;
    outline: none;
    box-shadow: none;
}

.btn:hover {
    background-color: #04a785;
}

.oauth-buttons {
    margin-top: 20px;
}

.oauth-btn {
    background-color: #00b5ad;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 10px;
    cursor: pointer;
}

.oauth-btn:hover {
    background-color: #009c9a;
}

.bottom-logo {
    position: absolute;
    bottom: 20px;
    right: 20px;
}
